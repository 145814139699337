import { create } from 'zustand'

interface QuizStore {
  q1: number
  q2: number
  q3: number
  q4: number
  q5: number
  q6: number
  q7: number
  q8: number
  q9: number
  q10: number
  totalPoint: number
  quizResult: string | null
  setQ1: (state: number) => void
  setQ2: (state: number) => void
  setQ3: (state: number) => void
  setQ4: (state: number) => void
  setQ5: (state: number) => void
  setQ6: (state: number) => void
  setQ7: (state: number) => void
  setQ8: (state: number) => void
  setQ9: (state: number) => void
  setQ10: (state: number) => void
  setTotalPoint: (state: number) => void
  setQuizResult: (state: string | null) => void
  reset: () => void
}

const useQuiz = create<QuizStore>((set) => ({
  q1: -1,
  q2: -1,
  q3: -1,
  q4: -1,
  q5: -1,
  q6: -1,
  q7: -1,
  q8: -1,
  q9: -1,
  q10: -1,
  totalPoint: 0,
  quizResult: null,
  setQ1: (state) => set({ q1: state}),
  setQ2: (state) => set({ q2: state}),
  setQ3: (state) => set({ q3: state}),
  setQ4: (state) => set({ q4: state}),
  setQ5: (state) => set({ q5: state}),
  setQ6: (state) => set({ q6: state}),
  setQ7: (state) => set({ q7: state}),
  setQ8: (state) => set({ q8: state}),
  setQ9: (state) => set({ q9: state}),
  setQ10: (state) => set({ q10: state}),
  setTotalPoint: (state) => set({ totalPoint: state}),
  setQuizResult: (state) => set({ quizResult: state}),
  reset: () => set({
    q1: -1,
    q2: -1,
    q3: -1,
    q4: -1,
    q5: -1,
    q6: -1,
    q7: -1,
    q8: -1,
    q9: -1,
    q10: -1,
    totalPoint: 0,
    quizResult: null,
  })
}))

export default useQuiz
