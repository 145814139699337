import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import CardHeader from '../components/CardHeader'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { answerQ1, questionQ1 } from '../constants'

const Quiz01: React.FC = () => {
  const { mainSwiper } = useCommon()
  const { q1, setQ1 } = useQuiz()
  const debouncedAnswer = useDebounce(q1, 300)

  React.useEffect(() => {
    if (debouncedAnswer !== -1) {
      setTimeout(() => {
        mainSwiper?.slideNext()
      }, 50)
    }
  }, [debouncedAnswer])

  const handleSelectAnswer = (idx: number) => {
    if (q1 === idx) {
      // setQ1(-1)
    } else {
      setQ1(idx)
    }
  }

  return (
    <QuizStyled>
      <CardHeader num='01' title={questionQ1} />

      <div tw='my-3 flex items-center justify-center w-full'>
        <div tw='flex flex-col flex-wrap items-center justify-center md:w-[80%] gap-3'>
          {answerQ1.map((i, idx) => (
            <div
              key={i}
              className={clsx(
                'quiz01tag',
                {
                  quiz01tagNormal: idx !== q1,
                  quiz01tagActive: idx === q1,
                })
              }
              onClick={() => { handleSelectAnswer(idx) }}
            >{i}</div>
          ))}
        </div>

      </div>
      {/* <div>Your selected: {answerQ1[q1]}</div> */}
    </QuizStyled>
  )
}

export default Quiz01

const QuizStyled = styled.div`
  .quiz01tag {
    ${tw`w-full select-none border border-solid border-white text-black px-4 py-2 rounded-full text-base md:text-lg cursor-pointer`};
  }
  .quiz01tagNormal {
    ${tw`bg-white`};
  }
  .quiz01tagActive {
    ${tw`bg-[#C9DA2B]`};
  }
`