import React from 'react'
import { css } from '@emotion/react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import LoadingSpinner from './LoadingSpinner'

type ButtonTypes = HTMLAnchorElement | HTMLButtonElement

interface ButtonOrLinkProps<T extends ButtonTypes> extends React.AnchorHTMLAttributes<T>, React.ButtonHTMLAttributes<T> {
  children: React.ReactNode
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  isLoading?: boolean
  disabled?: boolean
  radiusFull?: boolean
  variant?: 'primary' | 'secondary' | 'outline' | 'success' | 'warning' | 'danger' | 'successOutline' | 'warningOutline' | 'dangerOutline'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  fontSize?: 'normal' | 'bold'
  onClick?: () => void
  href?: string
  type?: 'submit' | 'reset' | 'button'
}

const defaultStyle = css`
  ${tw`inline-flex items-center justify-center border box-border rounded-lg font-medium transition-colors duration-150 select-none`};
`

const primaryStyle = css`
  ${tw`text-white bg-primary-500 border-primary-500 hover:bg-primary-600 hover:border-primary-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-opacity-50`};
`

const secondaryStyle = css`
  ${tw`text-primary bg-white border-white hover:bg-gray-200 hover:border-gray-200 hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50`};
`

const outlineStyle = css`
  ${tw`text-secondary-500 bg-transparent border-2 border-solid border-secondary-500 hover:text-white hover:bg-secondary-500 focus:outline-none focus:ring-2 focus:ring-secondary-600 focus:ring-opacity-50`};
`

const successStyle = css`
  ${tw`text-white bg-success-600 border-success-600 hover:bg-success-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-success-700 focus:ring-opacity-50`};
`

const warningStyle = css`
  ${tw`text-white bg-warning-500 border-warning-500 hover:bg-warning-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-warning-600 focus:ring-opacity-50`};
`

const dangerStyle = css`
  ${tw`text-white bg-danger-500 border-danger-500 hover:bg-danger-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-danger-600 focus:ring-opacity-50`};
`

const outlineSuccessStyle = css`
  ${tw`text-success-600 bg-transparent border-2 border-solid border-success-600 hover:text-white hover:bg-success-600 focus:outline-none focus:ring-2 focus:ring-success-600 focus:ring-opacity-50`};
`

const outlineWarningStyle = css`
  ${tw`text-warning-500 bg-transparent border-2 border-solid border-warning-500 hover:text-white hover:bg-warning-500 focus:outline-none focus:ring-2 focus:ring-warning-500 focus:ring-opacity-50`};
`

const outlineDangerStyle = css`
  ${tw`text-danger-500 bg-transparent border-2 border-solid border-danger-500 hover:text-white hover:bg-danger-500 focus:outline-none focus:ring-2 focus:ring-danger-500 focus:ring-opacity-50`};
`

const buttonVariants = {
  primary: primaryStyle,
  secondary: secondaryStyle,
  outline: outlineStyle,
  success: successStyle,
  warning: warningStyle,
  danger: dangerStyle,
  successOutline: outlineSuccessStyle,
  warningOutline: outlineWarningStyle,
  dangerOutline: outlineDangerStyle
}

const primaryLoadingOrDisabledStyle = css`
  ${tw`bg-primary-300 border-primary-300 hover:bg-primary-300 focus:ring-0 cursor-not-allowed`};
`

const secondaryLoadingOrDisabledStyle = css`
  ${tw`bg-secondary-100/50 border-secondary-100/50 text-secondary-100 hover:bg-secondary-100/50 hover:border-secondary-100/50 hover:text-secondary-100 focus:ring-0 cursor-not-allowed`};
`

const outlineLoadingOrDisabledStyle = css`
  ${tw`bg-white border-2 border-solid border-primary-200 text-primary-200 hover:bg-white hover:text-primary-200 focus:ring-0 cursor-not-allowed`};
`

const successLoadingOrDisabledStyle = css`
  ${tw`bg-success-300 border-success-300 hover:bg-success-300 focus:ring-0 cursor-not-allowed`};
`

const warningLoadingOrDisabledStyle = css`
  ${tw`bg-warning-300 border-warning-300 hover:bg-warning-300 focus:ring-0 cursor-not-allowed`};
`

const dangerLoadingOrDisabledStyle = css`
  ${tw`bg-danger-300 border-danger-300 hover:bg-danger-300 focus:ring-0 cursor-not-allowed`};
`

const outlineSuccessLoadingOrDisabledStyle = css`
  ${tw`bg-white border-2 border-solid border-success-200 text-success-200 hover:bg-white hover:text-success-200 focus:ring-0 cursor-not-allowed`};
`

const outlineWarningLoadingOrDisabledStyle = css`
  ${tw`bg-white border-2 border-solid border-warning-200 text-warning-200 hover:bg-white hover:text-warning-200 focus:ring-0 cursor-not-allowed`};
`

const outlineDangeLoadingOrDisabledrStyle = css`
  ${tw`bg-white border-2 border-solid border-danger-200 text-danger-200 hover:bg-white hover:text-danger-200 focus:ring-0 cursor-not-allowed`};
`

const buttonLoadingOrDisabled = {
  primary: primaryLoadingOrDisabledStyle,
  secondary: secondaryLoadingOrDisabledStyle,
  outline: outlineLoadingOrDisabledStyle,
  success: successLoadingOrDisabledStyle,
  warning: warningLoadingOrDisabledStyle,
  danger: dangerLoadingOrDisabledStyle,
  successOutline: outlineSuccessLoadingOrDisabledStyle,
  warningOutline: outlineWarningLoadingOrDisabledStyle,
  dangerOutline: outlineDangeLoadingOrDisabledrStyle
}

const buttonSizes = {
  xs: tw`h-[24px] px-2 py-1 text-xs`,
  sm: tw`h-[32px] px-2 py-1 text-sm`,
  md: tw`h-[40px] px-3 py-2 text-base`,
  lg: tw`h-[48px] px-4 py-3 text-lg`,
}

const fontSizes = {
  normal: tw`font-medium`,
  bold: tw`font-bold`,
}

const ButtonStyled = styled.button<ButtonOrLinkProps<HTMLButtonElement>>((
  {
    variant = 'primary',
    size = 'md',
    fontSize = 'normal',
    isLoading = false,
    disabled = false,
    radiusFull = false
  }) =>
  css(
    defaultStyle,
    buttonVariants[variant],
    buttonSizes[size],
    fontSizes[fontSize],
    isLoading ? buttonLoadingOrDisabled[variant] : null,
    disabled ? buttonLoadingOrDisabled[variant] : null,
    radiusFull ? tw`rounded-full` : null
  )
)

const LinkStyled = styled.a<ButtonOrLinkProps<HTMLAnchorElement>>((
  {
    variant = 'primary',
    size = 'md',
    fontSize = 'normal',
    isLoading = false,
    disabled = false,
    radiusFull = false
  }) =>
  css(
    defaultStyle,
    buttonVariants[variant],
    buttonSizes[size],
    fontSizes[fontSize],
    isLoading ? buttonLoadingOrDisabled[variant] : null,
    disabled ? buttonLoadingOrDisabled[variant] : null,
    radiusFull ? tw`rounded-full` : null
  )
)

const ButtonOrLink: React.FC<ButtonOrLinkProps<ButtonTypes>> = (
  {
    children,
    onClick = () => { },
    disabled = false,
    isLoading = false,
    radiusFull = false,
    startIcon,
    endIcon,
    variant,
    size,
    fontSize,
    href = undefined,
    title = undefined,
    ...props
  }) => {

  const handleClick = () => {
    if (!disabled && !isLoading && onClick) {
      onClick()
    }
  }

  const isLink = !!href

  return (
    <>
      {isLink ? (
        <LinkStyled
          {...props}
          href={href ? href : '#'}
          title={title ? title : undefined}
          aria-label={title ? title : undefined}
          onClick={handleClick}
          isLoading={isLoading ? isLoading : undefined}
          disabled={isLoading ? isLoading : disabled ? disabled : undefined}
          radiusFull={radiusFull ? radiusFull : undefined}
          variant={variant}
          size={size}
          fontSize={fontSize}
        >
          {!isLoading && startIcon ? <span tw='mr-2 inline-flex'>{startIcon}</span> : null}
          {isLoading ? <span tw='mr-2 inline-flex'><LoadingSpinner /></span> : null}
          {children}
          {endIcon ? <span tw='ml-2 inline-flex'>{endIcon}</span> : null}
        </LinkStyled>
      ) : null}
      {!isLink ? (
        <ButtonStyled
          {...props}
          onClick={handleClick}
          isLoading={isLoading ? isLoading : undefined}
          disabled={isLoading ? isLoading : disabled ? disabled : undefined}
          radiusFull={radiusFull ? radiusFull : undefined}
          variant={variant}
          size={size}
          fontSize={fontSize}
        >
          {!isLoading && startIcon && <span tw='mr-2 inline-flex'>{startIcon}</span>}
          {isLoading && <span tw='mr-2 inline-flex'><LoadingSpinner /></span>}
          {children}
          {endIcon && <span tw='ml-2 inline-flex'>{endIcon}</span>}
        </ButtonStyled>
      ) : null}
    </>
  )
}

export default ButtonOrLink
