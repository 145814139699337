import React from 'react'
import ReactDOM from 'react-dom/client'
import GlobalStyles from './styles/GlobalStyles'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination.min.css'
import 'swiper/modules/navigation.min.css'
import './styles/globals.css'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>
)