import {
  answerQ1,
  answerQ2,
  answerQ3,
  answerQ4,
  answerQ5,
  answerQ6,
  answerQ7,
  answerQ8,
  answerQ9,
  answerQ10,
  questionQ1,
  questionQ2,
  questionQ3,
  questionQ4,
  questionQ5,
  questionQ6,
  questionQ7,
  questionQ8,
  questionQ9,
  questionQ10
} from '../constants'

export const makeAnswerString = (
  q1: number,
  q2: number,
  q3: number,
  q4: number,
  q5: number,
  q6: number,
  q7: number,
  q8: number,
  q9: number,
  q10: number
) => {
  const arr = []
  if (q1 !== -1) arr.push({ question: `1. ${questionQ1}`, answer: answerQ1[q1] })
  if (q2 !== -1) arr.push({ question: `2. ${questionQ2}`, answer: answerQ2[q2] })
  if (q3 !== -1) arr.push({ question: `3. ${questionQ3}`, answer: answerQ3[q3] })
  if (q4 !== -1) arr.push({ question: `4. ${questionQ4}`, answer: answerQ4[q4] })
  if (q5 !== -1) arr.push({ question: `5. ${questionQ5}`, answer: answerQ5[q5] })
  if (q6 !== -1) arr.push({ question: `6. ${questionQ6}`, answer: answerQ6[q6] })
  if (q7 !== -1) arr.push({ question: `7. ${questionQ7}`, answer: answerQ7[q7] })
  if (q8 !== -1) arr.push({ question: `8. ${questionQ8}`, answer: answerQ8[q8] })
  if (q9 !== -1) arr.push({ question: `9. ${questionQ9}`, answer: answerQ9[q9] })
  if (q10 !== -1) arr.push({ question: `10. ${questionQ10}`, answer: answerQ10[q10] })
  return JSON.stringify(arr)
}