import { create } from 'zustand'

interface CommonSettingStore {
  currentSlideIndex: number
  setCurrentSlideIndex: (state: number) => void
  mainSwiper: any
  setMainSwiper: (state: any) => void
  showFormInfo: boolean
  setShowFormInfo: (state: boolean) => void
  showConfirmModal: boolean
  setShowConfirmModal: (state: boolean) => void
  showSuccessModal: boolean
  setShowSuccessModal: (state: boolean) => void
  showUnlockSuperPower: boolean
  setShowUnlockSuperPower: (state: boolean) => void
  reset: () => void
}

const useCommon = create<CommonSettingStore>((set) => ({
  currentSlideIndex: 0,
  setCurrentSlideIndex: (state) => set({
    currentSlideIndex: state
  }),
  mainSwiper: null,
  setMainSwiper: (state) => set({
    mainSwiper: state
  }),
  showFormInfo: false,
  setShowFormInfo: (state) => set({
    showFormInfo: state
  }),
  showConfirmModal: false,
  setShowConfirmModal: (state) => set({
    showConfirmModal: state
  }),
  showSuccessModal: false,
  setShowSuccessModal: (state) => set({
    showSuccessModal: state
  }),
  showUnlockSuperPower: false, // false
  setShowUnlockSuperPower: (state) => set({
    showUnlockSuperPower: state
  }),
  reset: () => set({
    showConfirmModal: false,
    showSuccessModal: false,
  })
}))

export default useCommon
