import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'

import useMobileDetect from '../hooks/useMobileDetect'
import Logo from '../assets/logo.png'

const navLinks = [
  { key: 1, href: 'https://asure.bio/', title: 'Asure Bio', label: 'Our Bio' },
  { key: 2, href: 'https://asure.bio/services/', title: 'Services', label: 'Services' },
  { key: 3, href: 'https://asure.bio/portfolio/', title: 'Portfolio', label: 'Portfolio' },
  { key: 4, href: 'https://asure.bio/contact/', title: 'Contact Us', label: 'Contact Us' },
]

const TopNavigationBar: React.FC = () => {
  const [showMenu, setShowMenu] = React.useState(false)
  const { isMobile } = useMobileDetect()

  const handleToggleMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <TopNavigationBarStyled>
      {isMobile ? (
        <div className='iconBar' onClick={handleToggleMenu}>
        {!showMenu ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            tabIndex={0}
            viewBox='0 0 448 512'
            fill='currentColor'
            height="100%" width="100%"
          >
            <path d='M432 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z' />
          </svg>
        ) : (
          <svg
            aria-hidden='true'
            tabIndex={0}
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            fill='currentColor'
            height="100%" width="100%"
          >
            <path d='M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6L295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z'></path>
          </svg>
        )}

      </div>
      ) : null}

      <div
        className={clsx('wrapMenu' , {
          isMobileMenu: isMobile,
          isMobileMenuShow: isMobile && showMenu,
        })}
      >
        {navLinks.map((i: any) => (
          <a key={i.key} href={i.href} className='menuItem' title={i.title}>{i.label}</a>
        ))}
      </div>
      <a className='wrapLogo' href='https://asure.bio/' title='Asure Bio' aria-label='Asure Bio'>
        <img
          tw='!object-contain !w-full !h-full relative z-[1]'
          src={Logo}
        />
      </a>
    </TopNavigationBarStyled>
  )
}

export default TopNavigationBar


const TopNavigationBarStyled = styled.div`
  ${tw`relative container mx-auto px-4 h-[95px] md:h-[160px] p-[10px] box-border flex items-center justify-between gap-2`};
  .wrapMenu {
    ${tw`relative top-0 flex items-center gap-2 h-[80px] p-[10px] box-border`};
  }
  .isMobileMenu {
    ${tw`hidden absolute w-full left-0 top-[70px] z-[2]`};
  }
  .isMobileMenuShow {
    ${tw`!block`};
  }
  .iconBar {
    ${tw`h-[50px] w-[50px] p-[10px] box-border text-white flex items-center cursor-pointer select-none`};
    svg {
      ${tw`focus:outline-none`};
    }
  }
  .wrapLogo {
    ${tw`block w-[54px] h-[46px]`};
  }
  .menuItem {
    ${tw`relative bg-black w-full block md:bg-transparent md:w-[auto] px-[30px] py-[15px] text-[20px] lg:text-[24px] font-semibold leading-[1]`};
    &:hover {
      &::after {
        ${tw`hidden md:block absolute z-[2] content-[''] bottom-0 w-[50%] h-[1px] left-[25%] mb-[12px] bg-white`};
      }
    }
  }
`