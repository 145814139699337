import {
  pointQ1,
  pointQ2,
  pointQ3,
  pointQ4,
  pointQ5,
  pointQ6,
  pointQ7,
  pointQ8,
  pointQ9,
  pointQ10,
} from '../constants'

export const calcTotalPoint = (
  q1: number,
  q2: number,
  q3: number,
  q4: number,
  q5: number,
  q6: number,
  q7: number,
  q8: number,
  q9: number,
  q10: number
) => {
  let total = 0
  if (q1 !== -1) total += pointQ1[q1]
  if (q2 !== -1) total += pointQ2[q2]
  if (q3 !== -1) total += pointQ3[q3]
  if (q4 !== -1) total += pointQ4[q4]
  if (q5 !== -1) total += pointQ5[q5]
  if (q6 !== -1) total += pointQ6[q6]
  if (q7 !== -1) total += pointQ7[q7]
  if (q8 !== -1) total += pointQ8[q8]
  if (q9 !== -1) total += pointQ9[q9]
  if (q10 !== -1) total += pointQ10[q10]

  if (total <= 10) {
    total = 10
  } else if (total <= 20 && total > 10) {
    total = 20
  } else if (total <= 30 && total > 20) {
    total = 30
  } else if (total <= 40 && total > 30) {
    total = 40
  } else if (total <= 50 && total > 40) {
    total = 50
  } else {
    total = 60
  }

  return total
}