import React from 'react'
import Button from '../components/Button'
import Modal from '../components/Modal'
import useCommon from '../hooks/zustand/useCommon'

interface SubmitConfirmModalProps {
  isOpen?: boolean
  hideXButton?: boolean
  onClose: () => void
}

const removeHashFromUrl = (): void => {
  const currentUrl = window.location.href

  if (currentUrl.includes('#')) {
    const newUrl = currentUrl.split('#')[0]
    window.history.replaceState(null, '', newUrl)
  }
}

const SubmitConfirmModal: React.FC<SubmitConfirmModalProps> = ({
  isOpen,
  hideXButton = false,
  onClose
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const {
    setShowFormInfo
  } = useCommon()

  const handleConfirm = () => {
    setShowFormInfo(true)
    removeHashFromUrl()
    onClose()
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose} hideXButton={hideXButton}>
        <h2 tw='mt-6 text-2xl text-center font-semibold leading-7 text-gray-100'>Submit Result</h2>
        <p tw='mt-1 text-base text-center leading-6 text-gray-200 mb-4'>Have you confirmed the choices selected for the quiz?</p>

        <div tw='mt-6 flex items-center justify-center gap-x-4'>
          <Button
            disabled={isLoading}
            onClick={onClose}
            type='button'
            variant='secondary'
            radiusFull
            size='lg'
            tw='px-10'
          >
            No
          </Button>
          <Button
            disabled={isLoading}
            type='button'
            variant='primary'
            onClick={() => { handleConfirm() }}
            radiusFull
            size='lg'
            tw='px-10'
          >
            Yes
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default SubmitConfirmModal