import { useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import toast, { Toaster, useToasterStore } from 'react-hot-toast'

const useToast = () => {
  const { toasts } = useToasterStore()
  const TOAST_LIMIT = 3
  const toastSuccess = useCallback((msg: JSX.Element | string, duration = 3000): void => {
    toast.success(msg, { duration })
  }, [])

  const toastError = useCallback((msg: JSX.Element | string, duration = 3000): void => {
    toast.error(msg, { duration })
  }, [])

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)) // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts])

  const toasterComponent = useMemo(() => {
    return (
      <LayoutWrapper>
        <Toaster toastOptions={{ className: 'toast' }} />
      </LayoutWrapper>
    )
  }, [])

  return { toastSuccess, toastError, toasterComponent }
}

export default useToast

// language=SCSS prefix=*{ suffix=}
const LayoutWrapper = styled.div`
  .toast {
    ${tw`bg-gray-800 text-white`}
  }
`
