import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import CardHeader from '../components/CardHeader'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { answerQ2, questionQ2 } from '../constants'

const Quiz02: React.FC = () => {
  const { mainSwiper } = useCommon()
  const { q2, setQ2 } = useQuiz()
  const debouncedAnswer = useDebounce(q2, 300)

  React.useEffect(() => {
    if (debouncedAnswer !== -1) {
      setTimeout(() => {
        mainSwiper?.slideNext()
      }, 50)
    }
  }, [debouncedAnswer])

  const handleSelectAnswer = (idx: number) => {
    if (q2 === idx) {
      // setQ2(-1)
    } else {
      setQ2(idx)
    }
  }
  return (
    <QuizStyled>
      <CardHeader num='02' title={questionQ2} />

      <div className='wrapCircle'>
        {answerQ2.map((i, idx) => (
          <div
            key={i}
            className={clsx(
              `quiz02Cir p02cir0${idx+1}`,
              {
                p02Normal: idx !== q2,
                p02Active: idx === q2,
              })
            }
            onClick={() => { handleSelectAnswer(idx) }}
          >{i}</div>
        ))}
      </div>
    </QuizStyled>
  )
}

export default Quiz02

const QuizStyled = styled.div`
  .wrapCircle {
    ${tw`my-3 w-full relative flex md:block justify-center md:justify-start flex-wrap gap-2 mb-32 font-medium md:font-normal`};
  }
  .quiz02Cir {
    ${tw`select-none md:absolute border border-solid border-white text-black rounded-full cursor-pointer text-xs md:text-base flex items-center justify-center text-center`};
  }
  .p02Normal {
    ${tw`bg-white`};
  }
  .p02Active {
    ${tw`bg-[#C9DA2B]`};
  }
  .p02cir01 {
    ${tw`w-[140px] h-[140px] md:w-[140px] md:h-[140px] md:left-[50px]  md:top-[20px] p-2`};
  }
  .p02cir02 {
    ${tw`w-[140px] h-[140px] md:w-[140px] md:h-[140px] md:left-[210px] md:top-[-20px] p-2`};
  }
  .p02cir03 {
    ${tw`w-[140px] h-[140px] md:w-[206px] md:h-[206px] md:left-[370px] md:top-[-20px] p-2 md:p-4`};
  }
  .p02cir04 {
    ${tw`w-[140px] h-[140px] md:w-[170px] md:h-[170px] md:left-[230px] md:top-[140px] p-2`};
  }
  .p02cir05 {
    ${tw`w-[140px] h-[140px] md:w-[206px] md:h-[206px] md:left-[10px] md:top-[180px] p-2 md:p-4`};
  }
  .p02cir06 {
    ${tw`w-[140px] h-[140px] md:w-[140px] md:h-[140px] md:left-[415px] md:top-[210px] p-2`};
  }
`