import { useState, useEffect } from 'react'
import { theme } from 'twin.macro'

type MobileDetectResult = {
  isMobile: boolean
}

const useMobileDetect = (): MobileDetectResult => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < parseInt(theme`screens.md`))
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile }
}

export default useMobileDetect
