import React from 'react'
import 'twin.macro'
import styled from '@emotion/styled'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import Button from '../components/Button'
import Input from '../components/Input'

import useToast from '../hooks/useToast'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import useFormInfo from '../hooks/zustand/useFormInfo'
import postContactForm from '../utils/postContactForm'
import { makeAnswerString } from '../utils/makeAnswerString'

const FormInfo: React.FC = () => {
  const { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, totalPoint } = useQuiz()
  const { setShowSuccessModal } = useCommon()
  const {
    name,
    email,
    companyName,
    setName,
    setEmail,
    setCompanyName
  } = useFormInfo()
  const { toastError, toastSuccess } = useToast()
  const [isLoading, setIsLoading] = React.useState(false)

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: {
      errors
    }
  } = useForm<FieldValues>({
    defaultValues: {
      name: '',
      email: '',
      companyName: '',
    }
  })

  const watchFormName = watch('name', '')
  const watchFormEmail = watch('email', '')
  const watchFormCompanyName = watch('companyName', '')
  const debouncedFormName = useDebounce(watchFormName, 50)
  const debouncedFormEmail = useDebounce(watchFormEmail, 50)
  const debouncedFormCompanyName = useDebounce(watchFormCompanyName, 50)

  React.useEffect(() => {
    setName(watchFormName)
  }, [debouncedFormName])

  React.useEffect(() => {
    setEmail(watchFormEmail)
  }, [debouncedFormEmail])

  React.useEffect(() => {
    setCompanyName(watchFormCompanyName)
  }, [debouncedFormCompanyName])

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setIsLoading(true)
    if (!data?.name || data?.name?.trim().length === 0) {
      toastError('Please input your name!')
      setIsLoading(false)
      return
    }
    if (!data?.email || data?.email?.trim().length === 0) {
      toastError('Please input your email!')
      setIsLoading(false)
      return
    }
    if (!data?.companyName || data?.companyName?.trim().length === 0) {
      toastError('Please input your company name!')
      setIsLoading(false)
      return
    }
    const params = {
      name,
      email,
      company_email: email,
      company_name: companyName,
      total_point: totalPoint,
      answers: makeAnswerString(q1, q2, q3, q4, q5, q6, q7, q8, q9, q10)
    }
    // console.log('formInfo: ', params)
    postContactForm(params)
      .then((res) => {
        // console.log(res)
        if (res?.status === 200){
          setShowSuccessModal(true)
          setTimeout(() => {
            setIsLoading(false)
            setValue('name', '', { shouldValidate: false })
            setValue('email', '', { shouldValidate: false })
            setValue('companyName', '', { shouldValidate: false })
            setName('')
            setEmail('')
            setCompanyName('')
          }, 1000)
        } else {
          toastError(`${res?.data?.message}: ${JSON.stringify(res?.data?.error_detail).replace(/[^a-zA-Z":.]+/g, ' ').trim()}`)
        }
      })
      .catch((e) => {
        console.log(e)
        toastError('Error occur! Please try again!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return (
    <FormStyled>
      <div tw='text-center md:mt-6 mb-6'>You'll get an exclusive PDF specific to your personality type,<br tw='hidden md:block' />plus more resources & brand insights.</div>
      <div tw='flex items-center justify-center w-full'>
        <div tw='w-[90%] md:w-[430px]'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div tw='mb-4'>
              <Input
                id='name'
                autoComplete='off'
                label='Your Name'
                placeholder='Your Name'
                register={register}
                disabled={isLoading}
                required
                errors={errors}
              />
            </div>
            <div tw='mb-4'>
              <Input
                id='email'
                type='email'
                autoComplete='off'
                label='Email Address'
                placeholder='Email Address'
                register={register}
                disabled={isLoading}
                required
                errors={errors}
              />
            </div>
            <div tw='mb-4'>
              <Input
                id='companyName'
                autoComplete='off'
                label='Company Name'
                placeholder='Company Name'
                register={register}
                disabled={isLoading}
                required
                errors={errors}
              />
            </div>
            <div tw='mt-6 flex items-center justify-center gap-x-4'>
              <Button
                disabled={isLoading}
                type='submit'
                variant='primary'
                size='lg'
                radiusFull
                tw='px-10'
              >See My Result
              </Button>
            </div>
          </form>
        </div>
      </div>

    </FormStyled>
  )
}

export default FormInfo


const FormStyled = styled.div`

`