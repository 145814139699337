import React from 'react'
import 'twin.macro'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { isNumeric } from '../utils/isNumeric'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: number
  stroke?: number
  color?: string
}

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const LdS = styled.div<Props>`
  animation: 0.45s linear 0s infinite normal none running ${spinAnimation};
  width: ${({ size }) => (isNumeric(size) ? `${size}px` : size)};
  height: ${({ size }) => (isNumeric(size) ? `${size}px` : size)};
  display: inline-block;
  border-top: ${({ stroke }) => (isNumeric(stroke) ? `${stroke}px` : stroke)} solid ${({ color }) => color ?? 'currentColor'};
  border-right: ${({ stroke }) => (isNumeric(stroke) ? `${stroke}px` : stroke)} solid ${({ color }) => color ?? 'currentColor'};
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  color: currentcolor;
  > span {
    border: 0;
    clip: rect(0px, 0px, 0px, 0px);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
  }
`

const LoadingSpinner: React.FC<Props> = ({ size = 16, stroke = 2, color, ...props }) => {
  return (
    <LdS {...props} size={size} stroke={stroke} color={color}>
      <span>Loading...</span>
    </LdS>
  )
}

export default LoadingSpinner
