import React from 'react'
import Button from '../components/Button'
import Modal from '../components/Modal'
import useCommon from '../hooks/zustand/useCommon'

interface SubmitSuccessModalProps {
  isOpen?: boolean
  hideXButton?: boolean
  onClose: () => void
}

const SubmitSuccessModal: React.FC<SubmitSuccessModalProps> = ({
  isOpen,
  hideXButton = false,
  onClose
}) => {
  const { setShowUnlockSuperPower } = useCommon()
  const [isLoading, setIsLoading] = React.useState(false)

  const handleOkay = () => {
    setIsLoading(true)
    setShowUnlockSuperPower(true)
    setTimeout(() => {
      setIsLoading(false)
      onClose()
    }, 50)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} hideXButton={hideXButton}>
      <div tw='flex items-center justify-center'>
        <svg xmlns='http://www.w3.org/2000/svg' width='167' height='113' fill='none'>
          <circle cx='82.083' cy='70.272' r='42.458' fill='#4FB139' fillOpacity='.13' />
          <g clipPath='url(#a)'>
            <path fill='#4FB139' fillRule='evenodd' d='M46.703 70.27a35.381 35.381 0 1 1 70.763 0 35.381 35.381 0 0 1-70.763 0Zm33.362 15.143 20.371-25.465-3.68-2.944-17.37 21.706-12.303-10.251-3.02 3.623 16.002 13.336v-.005Z' clipRule='evenodd' />
          </g>
          <circle cx='135.863' cy='92.915' r='.915' stroke='#4FB139' />
          <circle cx='31.842' cy='31.351' r='1.623' stroke='#4FB139' />
          <circle cx='2.123' cy='72.392' r='1.623' stroke='#4FB139' />
          <circle cx='164.878' cy='29.937' r='1.623' stroke='#EF8810' strokeOpacity='.5' />
          <path fill='#4FB139' d='m91.305 12.246-1.428-1.438-1.427 1.438-.705-.71 1.437-1.437-1.4-1.409.686-.69 1.4 1.409L91.268 8l.704.71-1.4 1.408 1.419 1.438-.686.69Z' />
          <path fill='#EF8810' fillOpacity='.5' d='m33.279 81.593-1.428-1.437-1.428 1.437-.704-.709 1.437-1.437-1.4-1.41.686-.69 1.4 1.41 1.4-1.41.704.71-1.4 1.409 1.419 1.437-.686.69Z' />
          <path stroke='#EF8810' strokeLinecap='round' strokeOpacity='.5' d='M131.62 63.195h5.661M53.781.924l1.368 5.493M116.257 7.096l-3.246 4.638' />
          <defs>
            <clipPath id='a'>
              <path fill='#fff' d='M46.703 34.889h70.763v70.763H46.703z' />
            </clipPath>
          </defs>
        </svg>
      </div>
      <h2 tw='mt-6 text-2xl text-center font-semibold leading-7 text-gray-100'>Thank You</h2>
      <p tw='mt-1 text-base text-center leading-6 text-gray-200 mb-4'>Kindly check your email for the PDF Inspiration Kit.</p>

      <div tw='mt-6 flex items-center justify-center gap-x-4'>
        <Button
          disabled={isLoading}
          type='button'
          variant='primary'
          onClick={handleOkay}
          radiusFull
          size='lg'
          tw='px-10'
        >
          Okay
        </Button>
      </div>
    </Modal>
  )
}

export default SubmitSuccessModal