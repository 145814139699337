import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import CardHeader from '../components/CardHeader'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { answerQ6, questionQ6 } from '../constants'

const Quiz06: React.FC = () => {
  const { mainSwiper } = useCommon()
  const { q6, setQ6 } = useQuiz()
  const debouncedAnswer = useDebounce(q6, 300)

  React.useEffect(() => {
    if (debouncedAnswer !== -1) {
      setTimeout(() => {
        mainSwiper?.slideNext()
      }, 50)
    }
  }, [debouncedAnswer])

  const handleSelectAnswer = (idx: number) => {
    if (q6 === idx) {
      // setQ6(-1)
    } else {
      setQ6(idx)
    }
  }

  return (
    <QuizStyled>
      <CardHeader num='06' title={questionQ6} />

      <div tw='my-3 flex items-center justify-center w-full'>
        <div tw='flex flex-col flex-wrap items-center justify-center md:w-[80%] gap-3'>
          {answerQ6.map((i, idx) => (
            <div
              key={i}
              className={clsx(
                'quiz06tag',
                {
                  quiz06tagNormal: idx !== q6,
                  quiz06tagActive: idx === q6,
                })
              }
              onClick={() => { handleSelectAnswer(idx) }}
            >{i}</div>
          ))}
        </div>
      </div>
    </QuizStyled>
  )
}

export default Quiz06

const QuizStyled = styled.div`
  .quiz06tag {
    ${tw`w-full select-none border border-solid border-white text-black px-4 py-2 rounded-full text-base md:text-lg cursor-pointer`};
  }
  .quiz06tagNormal {
    ${tw`bg-white`};
  }
  .quiz06tagActive {
    ${tw`bg-[#C9DA2B]`};
  }
`