import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import CardHeader from '../components/CardHeader'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { answerQ8, questionQ8 } from '../constants'

import Img01 from '../assets/q8/q8-01.jpg'
import Img02 from '../assets/q8/q8-02.jpg'
import Img03 from '../assets/q8/q8-03.jpg'
import Img04 from '../assets/q8/q8-04.jpg'
import Img05 from '../assets/q8/q8-05.jpg'
import Img06 from '../assets/q8/q8-06.jpg'

const Quiz08: React.FC = () => {
  const { mainSwiper } = useCommon()
  const { q8, setQ8 } = useQuiz()
  const debouncedAnswer = useDebounce(q8, 300)

  React.useEffect(() => {
    if (debouncedAnswer !== -1) {
      setTimeout(() => {
        mainSwiper?.slideNext()
      }, 50)
    }
  }, [debouncedAnswer])

  const handleSelectAnswer = (idx: number) => {
    if (q8 === idx) {
      // setQ8(-1)
    } else {
      setQ8(idx)
    }
  }

  const makeImg = React.useCallback((idx: number) => {
    switch (idx) {
      case 0:
        return Img01
      case 1:
        return Img02
      case 2:
        return Img03
      case 3:
        return Img04
      case 4:
        return Img05
      case 5:
        return Img06
      default:
        return
    }
  }, [])

  return (
    <QuizStyled>
      <CardHeader num='08' title={questionQ8} />

      <div className='wrapImage'>
        <div className='contentImage'>
          <div className='gridImage'>
            {answerQ8.map((i: string, idx: number) => (
              <div
                key={i}
                className={clsx(
                  `quiz08Img`,
                  {
                    p08Normal: idx !== q8 && q8 === -1,
                    p08Disable: idx !== q8 && q8 !== -1,
                    p08Active: idx === q8,
                  })
                }
                onClick={() => { handleSelectAnswer(idx) }}
              >
                <img
                  tw='!object-contain !w-full !h-full relative z-[1]'
                  src={makeImg(idx)}
                />
                <div className='p08WrapText'>
                  <div className='p08Text'>
                    <div>&#8220;</div>
                    <div>{i}</div>
                    <div>&#8221;</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </QuizStyled>
  )
}

export default Quiz08

const QuizStyled = styled.div`
  ${tw`block`};

  .wrapImage {
    ${tw`flex items-center justify-center w-full mb-[100px]`};
  }
  .contentImage {
    ${tw`flex items-center justify-center w-[80%] md:w-[65%] lg:w-[45%] xl:w-[35%]`};
  }
  .gridImage {
    ${tw`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4`};
  }
  .quiz08Img {
    ${tw`col-span-1 relative select-none`};
    > img {
      ${tw`border border-solid border-white/0 rounded-[24px]`};
    }
  }
  .p08Normal {
    ${tw`cursor-pointer`};
  }
  .p08Disable {
    ${tw`cursor-pointer grayscale`};
  }
  .p08Active {
    ${tw`cursor-pointer`};
    > img {
      ${tw`border border-solid border-[#C9DA2B]`};
    }
  }
  .p08WrapText {
    ${tw`absolute w-full h-full flex items-center justify-center bottom-[-10px] z-[2]`};
  }
  .p08Text {
    ${tw`flex flex-col justify-center
    leading-[18px] md:leading-[23px] px-2 text-left`};
    > div {
      &:first-of-type {
        ${tw`mt-6 text-2xl md:text-5xl font-bold !leading-[8px]`};
      }
      &:nth-of-type(2) {
        ${tw`md:h-[100px] lg:h-[80px] flex flex-col justify-center text-center font-medium md:font-bold text-[10px] !leading-[12px] md:!leading-[20px] md:text-base lg:text-base`};
      }
      &:last-of-type {
        ${tw`leading-[40px] text-2xl md:text-5xl font-bold text-right`};
      }
    }
  }
`