import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'

interface ICardHeaderProps {
  num?: string
  title?: string
}

const CardHeader: React.FC<ICardHeaderProps> = ({ num = '00', title = '' }) => {
  return (
    <CardHeaderStyled>
      <div className='cardHeaderNum'>{num}</div>
      <div className='cardHeaderTitle'>{title}</div>
    </CardHeaderStyled>
  )
}

export default CardHeader

const CardHeaderStyled = styled.div`
  ${tw`flex items-center justify-center w-full gap-4`};
  .cardHeaderNum {
    ${tw`pl-5 md:pl-0 leading-normal text-[70px] md:text-[140px] md:leading-[180px] font-bold`};
  }
  .cardHeaderTitle {
    ${tw`pr-5 text-left text-base md:text-3xl md:w-[420px]`};
  }
`