import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import CardHeader from '../components/CardHeader'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { answerQ9, questionQ9 } from '../constants'

const Quiz09: React.FC = () => {
  const { mainSwiper } = useCommon()
  const { q9, setQ9 } = useQuiz()
  const debouncedAnswer = useDebounce(q9, 300)

  React.useEffect(() => {
    if (debouncedAnswer !== -1) {
      setTimeout(() => {
        mainSwiper?.slideNext()
      }, 50)
    }
  }, [debouncedAnswer])

  const handleSelectAnswer = (idx: number) => {
    if (q9 === idx) {
      // setQ9(-1)
    } else {
      setQ9(idx)
    }
  }
  return (
    <QuizStyled>
      <CardHeader num='09' title={questionQ9} />

      <div className='wrapCircle'>
        {answerQ9.map((i, idx) => (
          <div
            key={i}
            className={clsx(
              `quiz09Cir p09cir0${idx + 1}`,
              {
                p09Normal: idx !== q9,
                p09Active: idx === q9,
              })
            }
            onClick={() => { handleSelectAnswer(idx) }}
          >{i}</div>
        ))}
      </div>
    </QuizStyled>
  )
}

export default Quiz09

const QuizStyled = styled.div`
  .wrapCircle {
    ${tw`my-3 w-full relative flex md:block justify-center md:justify-start flex-wrap gap-2 mb-32 font-medium md:font-normal`};
  }
  .quiz09Cir {
    ${tw`select-none md:absolute border border-solid border-white text-black rounded-full cursor-pointer text-xs md:text-base flex items-center justify-center text-center`};
  }
  .p09Normal {
    ${tw`bg-white`};
  }
  .p09Active {
    ${tw`bg-[#C9DA2B]`};
  }
  .p09cir01 {
    ${tw`w-[140px] h-[140px] md:w-[140px] md:h-[140px] md:left-[50px]  md:top-[20px] p-2`};
  }
  .p09cir02 {
    ${tw`w-[140px] h-[140px] md:w-[140px] md:h-[140px] md:left-[210px] md:top-[-20px] p-2`};
  }
  .p09cir03 {
    ${tw`w-[140px] h-[140px] md:w-[206px] md:h-[206px] md:left-[370px] md:top-[-20px] p-2 md:p-4`};
  }
  .p09cir04 {
    ${tw`w-[140px] h-[140px] md:w-[170px] md:h-[170px] md:left-[230px] md:top-[140px] p-2`};
  }
  .p09cir05 {
    ${tw`w-[140px] h-[140px] md:w-[206px] md:h-[206px] md:left-[10px] md:top-[180px] p-2 md:p-4`};
  }
  .p09cir06 {
    ${tw`w-[140px] h-[140px] md:w-[140px] md:h-[140px] md:left-[415px] md:top-[210px] p-2`};
  }
`