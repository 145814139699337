export const questionQ1 = 'What values are most important to your brand?'
export const questionQ2 = 'How would you like your brand to be perceived?'
export const questionQ3 = 'Which image resonates most with your brand?'
export const questionQ4 = 'How does your brand problem solve or provide value?'
export const questionQ5 = 'What tone of voice will your brand use?'
export const questionQ6 = "What do you consider as your brand's weakness?"
export const questionQ7 = 'Which image resonates most with your brand?'
export const questionQ8 = "What are your brand's greatest fear?"
export const questionQ9 = 'What are your brand goals?'
export const questionQ10 = 'Which quote resonates most with your brand?'

export const answerQ1 = [
  'Creativity and Self-expression',
  'Adventure and Exploration',
  'Independence and Self-Reliance',
  'Innovation and Progress',
  'Compassion and Care',
  'Growth and Self-improvement'
]

export const answerQ2 = [
  'Creative and Unique',
  'Adventurous and Curious',
  'Heroic and Influential',
  'Caring and Nurturing',
  'Transformative and Magical',
  'Fun and Entertaining'
]

export const answerQ3 = [
  'Art studio',
  'Epic adventure landscape',
  'Nicely made bed',
  'Joyful carnival',
  'Whimsical fantasy',
  'Person with a cape on a cliff'
]

export const answerQ4 = [
  'Provide Creative Ideas',
  'Explore New Possibilities and Options',
  'Empower Individuals',
  'Provide Care and Support',
  'Bring Transformation',
  'Bring Joy and Laughter'
]

export const answerQ5 = [
  'Creative and Expressive',
  'Adventurous and Exploratory',
  'Heroic and Inspiring',
  'Innocent and Optimistic',
  'Mysterious and Transformative',
  'Fun and Entertaining'
]

export const answerQ6 = [
  'Over Complication',
  'Restlessness',
  'Independence Struggle',
  'Naivety',
  'Lack of Focus',
  'Lack of Seriousness'
]

export const answerQ7 = [
  'Superman',
  'Magician',
  'Artist',
  'Explorer',
  'Teacher',
  'Comedian'
]

export const answerQ8 = [
  'Failing to Innovate or Meet Expectations.',
  'Missing out on New Opportunities.',
  'Not Living Up to Heroic ideals.',
  'Inability to Provide Care or Support.',
  'Failing to Create Transformative Experiences.',
  'Inability to Bring Joy or Entertainment.'
]

export const answerQ9 = [
  'Unleashing Creativity',
  'Embracing Adventure',
  'Empowering Individuals',
  'Making a Positive Impact',
  'Transforming Lives',
  'Bringing Laughter and Entertainment'
]

export const answerQ10 = [
  'Creativity takes courage.',
  'Not all those who wander are lost.',
  'Be the hero of your own story.',
  'Leadership is not about controlling but caring.',
  'Magic abounds - simply notice.',
  'Laughter is timeless; imagination knows no age.'
]

export const pointQ1 = [1,2,3,4,5,6]
export const pointQ2 = [1,2,3,5,4,6]
export const pointQ3 = [1,2,3,4,5,6]
export const pointQ4 = [1,2,3,5,4,6]
export const pointQ5 = [1,2,3,5,4,6]
export const pointQ6 = [1,2,3,5,4,6]
export const pointQ7 = [3,4,1,2,5,6]
export const pointQ8 = [1,2,3,5,4,6]
export const pointQ9 = [1,2,3,5,4,6]
export const pointQ10 = [1,2,3,5,4,6]