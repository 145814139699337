import { create } from 'zustand'

interface FormInfoStore {
  name: string
  email: string
  companyName: string
  setName: (state: string) => void
  setEmail: (state: string) => void
  setCompanyName: (state: string) => void
  reset: () => void
}

const useFormInfo = create<FormInfoStore>((set) => ({
  name: '',
  email: '',
  companyName: '',
  setName: (state) => set({ name: state}),
  setEmail: (state) => set({ email: state}),
  setCompanyName: (state) => set({ companyName: state}),
  reset: () => set({
    name: '',
    email: '',
    companyName: '',
  })
}))

export default useFormInfo
