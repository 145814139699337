import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import CardHeader from '../components/CardHeader'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { answerQ4, questionQ4 } from '../constants'

const Quiz04: React.FC = () => {
  const { mainSwiper } = useCommon()
  const { q4, setQ4 } = useQuiz()
  const debouncedAnswer = useDebounce(q4, 300)

  React.useEffect(() => {
    if (debouncedAnswer !== -1) {
      setTimeout(() => {
        mainSwiper?.slideNext()
      }, 50)
    }
  }, [debouncedAnswer])

  const handleSelectAnswer = (idx: number) => {
    if (q4 === idx) {
      // setQ4(-1)
    } else {
      setQ4(idx)
    }
  }
  return (
    <QuizStyled>
      <CardHeader num='04' title={questionQ4} />

      <div className='wrapCircle'>
        {answerQ4.map((i, idx) => (
          <div key={i} className='quiz04Cir'>
            <div
            className={clsx(
              'p04cir',
              {
                p04Normal: idx !== q4,
                p04Active: idx === q4,
              })
            }
            onClick={() => { handleSelectAnswer(idx) }}
          >{i}</div>
          </div>
        ))}
      </div>
    </QuizStyled>
  )
}

export default Quiz04

const QuizStyled = styled.div`
  .wrapCircle {
    ${tw`my-3 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 md:gap-4 font-medium md:font-normal
    items-center justify-center`};
  }
  .quiz04Cir {
    ${tw`select-none flex items-center mb-0 md:mb-4 w-full`};
    &:nth-child(odd) {
      ${tw`justify-end md:justify-center`};
    }
    &:nth-child(even) {
      ${tw`justify-start md:justify-center`};
    }
  }
  .p04cir {
    ${tw`border border-solid border-white text-black text-center rounded-full cursor-pointer text-xs md:text-base
    flex items-center justify-center w-[140px] h-[140px] md:w-[170px] md:h-[170px] p-2`};
  }
  .p04Normal {
    ${tw`bg-white`};
  }
  .p04Active {
    ${tw`bg-[#C9DA2B]`};
  }
`