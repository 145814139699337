import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'

interface IMacOSCardProps {
  title?: string
  isLight?: boolean
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  children: React.ReactNode
}


const MacOSCard: React.FC<IMacOSCardProps> = ({
  isLight = false,
  props,
  children
}) => {
  return (
    <CardStyled
      className={clsx({
        bgBlack: !isLight,
        bgWhite: isLight,
      })}
      {...props}
    >
      <div className='header'>
        <div className='dot red'>&nbsp;</div>
        <div className='dot yellow'>&nbsp;</div>
        <div className='dot green'>&nbsp;</div>
      </div>
      <div className='wrapContent'>{children}</div>
    </CardStyled>
  )
}

export default MacOSCard

const CardStyled = styled.div`
  ${tw`w-full min-h-[95vh] border-2 border-solid border-white rounded-[24px]
  overflow-hidden bg-black/60`};
  &.bgBlack {
    ${tw`bg-black/60 text-white`};
  }
  &.bgWhite {
    ${tw`bg-white text-black`};
  }
  .header {
    ${tw`h-[50px] bg-white flex items-center gap-2 px-6 select-none`};
    .dot {
      ${tw`w-[14px] h-[14px] rounded-full`};
    }
    .red {
      ${tw`bg-[#FD5E59]`};
    }
    .yellow {
      ${tw`bg-[#FEBA3B]`};
    }
    .green {
      ${tw`bg-[#34C648]`};
    }
  }
  .wrapContent {
    ${tw`h-full mb-12 md:mb-2`};
  }

  .hFull {
    ${tw`min-h-[78vh]`};
  }
`