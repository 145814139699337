import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/swiper-react'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination.min.css'
import 'swiper/modules/navigation.min.css'

// Import required modules
import { Pagination, Navigation, Controller, HashNavigation } from 'swiper/modules'

import Quiz01 from './Quiz01'
import Quiz02 from './Quiz02'
import Quiz03 from './Quiz03'
import Quiz04 from './Quiz04'
import Quiz05 from './Quiz05'
import Quiz06 from './Quiz06'
import Quiz07 from './Quiz07'
import Quiz08 from './Quiz08'
import Quiz09 from './Quiz09'
import Quiz10 from './Quiz10'

import FormInfo from './FormInfo'
import SubmitConfirmModal from './SubmitConfirmModal'
import SubmitSuccessModal from './SubmitSuccessModal'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { calcTotalPoint } from '../utils/calcTotalPoint'
import { makeAnswerString } from '../utils/makeAnswerString'
import { makeQuizResult } from '../utils/makeQuizResult'
import MacOSCard from './MacOSCard'


const MainSwiper: React.FC = () => {
  const [firstSwiper, setFirstSwiper] = useState<any>(null)
  const [secondSwiper, setSecondSwiper] = useState<any>(null)
  const swiperRef = React.useRef(null)
  const {
    currentSlideIndex,
    setCurrentSlideIndex,
    setMainSwiper,
    showConfirmModal,
    setShowConfirmModal,
    showSuccessModal,
    setShowSuccessModal,
    showFormInfo
  } = useCommon()
  const {
    q1,
    q2,
    q3,
    q4,
    q5,
    q6,
    q7,
    q8,
    q9,
    q10,
    totalPoint,
    setTotalPoint,
    setQuizResult
  } = useQuiz()
  const debouncedFinalAnswer = useDebounce(q10, 300)

  useEffect(() => {
    setTotalPoint(calcTotalPoint(q1, q2, q3, q4, q5, q6, q7, q8, q9, q10))
  }, [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10])

  useEffect(() => {
    setQuizResult(makeQuizResult(totalPoint))
  }, [totalPoint])

  useEffect(() => {
    if (debouncedFinalAnswer !== -1) {
      setTimeout(() => {
        if (![q1, q2, q3, q4, q5, q6, q7, q8, q9, q10].includes(-1)) {
          setShowConfirmModal(true)
        }
      }, 50)
    }
  }, [debouncedFinalAnswer])

  useEffect(() => {
    if (firstSwiper) {
      setMainSwiper(firstSwiper)
    }
  }, [firstSwiper])

  const handleCheckShowButtonNext = (idx: number) => {
    let checkBool = true
    switch (idx) {
      case 0:
        if (q1 === -1) checkBool = false
        break
      case 1:
        if (q2 === -1) checkBool = false
        break
      case 2:
        if (q3 === -1) checkBool = false
        break
      case 3:
        if (q4 === -1) checkBool = false
        break
      case 4:
        if (q5 === -1) checkBool = false
        break
      case 5:
        if (q6 === -1) checkBool = false
        break
      case 6:
        if (q7 === -1) checkBool = false
        break
      case 7:
        if (q8 === -1) checkBool = false
        break
      case 8:
        if (q9 === -1) checkBool = false
        break
      case 9:
        checkBool = false
        break
      default:
        break
    }
    return checkBool
  }

  const showNextButton = React.useMemo(() => {
    return handleCheckShowButtonNext(currentSlideIndex)
  }, [currentSlideIndex, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10])

  return (
    <React.Fragment>
      <MacOSCard>
        <div tw='px-4'>

        </div>
        {!showFormInfo ? (
          <h2>Discover Your Unique Bio</h2>
        ) : (
          <h2 tw='md:pt-20 font-bold'>Enter your email to get<br tw='hidden md:block' />your result and an Inspiration Kit.</h2>
        )}
        {/* <div>{q1} {q2} {q3} {q4} {q5} {q6} {q7} {q8} {q9} {q10}</div> */}
        <MainQuizStyled
          className={clsx({
            hideQuiz: showFormInfo,
            showQuiz: !showFormInfo,
          })}
        >
          <SwiperStyled
            ref={swiperRef}
            pagination={{
              type: 'progressbar',
              el: '.swiper-pagination',
            }}
            hashNavigation={{
              watchState: true,
            }}
            navigation={{
              enabled: true,
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Pagination, Navigation, Controller, HashNavigation]}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            allowTouchMove={false}
            onSlideChange={(swiperCore) => {
              const {
                // activeIndex,
                // snapIndex,
                // previousIndex,
                realIndex,
              } = swiperCore
              // console.log({ activeIndex, snapIndex, previousIndex, realIndex })
              setCurrentSlideIndex(realIndex)
            }}
          >
            <SwiperSlide data-hash='quiz-01'><div className='hFull'><Quiz01 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-02'><div className='hFull'><Quiz02 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-03'><div className='hFull'><Quiz03 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-04'><div className='hFull'><Quiz04 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-05'><div className='hFull'><Quiz05 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-06'><div className='hFull'><Quiz06 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-07'><div className='hFull'><Quiz07 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-08'><div className='hFull'><Quiz08 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-09'><div className='hFull'><Quiz09 /></div></SwiperSlide>
            <SwiperSlide data-hash='quiz-10'><div className='hFull'><Quiz10 /></div></SwiperSlide>
            <div className='swiper-button-prev'>
              <svg xmlns='http://www.w3.org/2000/svg' width='800' height='800' viewBox='0 0 24 24'><g fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'><path d='m7.6 7-5.1 5 5.1 5' data-name='Right' /><path d='M21.5 12H4.8' /></g></svg>
            </div>
            <div
              className={clsx('swiper-button-next', {
                hideButtonNext: !showNextButton
              })}
              tw='text-white font-bold'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='800' height='800' viewBox='0 0 24 24'><g fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'><path d='m16.4 7 5.1 5-5.1 5' data-name='Right' /><path d='M2.5 12h16.7' /></g></svg>
            </div>
            {showNextButton ? (
              <></>
            ) : null}
            <div tw='flex justify-center w-full'>
              <div tw='w-[50%]'>
                <div className='swiper-pagination'></div>
              </div>
            </div>
            <div className='swiper-pagination2'></div>
          </SwiperStyled>

          <SwiperStyled
            pagination={{
              el: '.swiper-pagination2',
              type: 'fraction',
            }}
            modules={[Pagination, Controller]}
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
            tw='select-none pointer-events-none h-0'
          >
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
            <SwiperSlide>&nbsp;</SwiperSlide>
          </SwiperStyled>
        </MainQuizStyled>
        <FormInfoStyled
          className={clsx({
            hideForm: !showFormInfo,
            showForm: showFormInfo,
          })}
        >
          <FormInfo />
        </FormInfoStyled>
      </MacOSCard>
      <SubmitConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        hideXButton
      />
      <SubmitSuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        hideXButton
      />
    </React.Fragment>
  )
}

export default MainSwiper

const FormInfoStyled = styled.div`
  ${tw`hidden`};
  &.hideForm {
    ${tw`pointer-events-none`};
  }
  &.showForm {
    ${tw`!block pointer-events-auto`};
  }
`

const MainQuizStyled = styled.div`
  ${tw`hidden`};
  &.hideQuiz {
    ${tw`pointer-events-none !h-[0] overflow-hidden`};
  }
  &.showQuiz {
    ${tw`!block pointer-events-auto`};
  }
`



const SwiperStyled = styled(Swiper)`
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-progressbar {
    top: inherit;
    bottom: 30px;
  }

  .swiper-pagination {
    position: relative;
    top: inherit;
    bottom: 65px;
    width: 100%;
    /* left: 50%; */
  }

  .swiper-pagination2 {
    position: absolute;
    bottom: 12px;
    display: block;
    width: 100%;
    text-align: center;
  }


  .swiper-button-next {
    ${tw`bottom-[0px] top-[inherit] z-[2] right-[25%] md:right-[40%] w-[50px] h-[50px] rounded-full p-2 bg-[#c9da2b] text-black`};
    &:after {
      ${tw`content-['']`}
    }
  }


  .swiper-button-prev {
    ${tw`bottom-[0px] top-[inherit] z-[2] left-[25%] md:left-[40%] w-[50px] h-[50px] rounded-full p-2 bg-[#c9da2b] text-black`};
    &:after {
      ${tw`content-['']`}
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .hideButtonNext {
    display: none;
  }
`