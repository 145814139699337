
import tw from 'twin.macro'
import styled from '@emotion/styled'
import clsx from 'clsx'
import IoClose from './svgs/IoClose'

interface ModalProps {
  isOpen?: boolean
  hideXButton?: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  hideXButton = false,
  onClose,
  children
}) => {
  return (
    <ModalStyled className={clsx(isOpen && 'showModal')}>
      <div className='modalWrapper'>
        <div className={clsx('modalOverlay', isOpen && 'showOverlay')} onClick={onClose} />
        <div className={clsx('modalBoxWrapper', isOpen && 'showBoxWrapper')}>
          <div className='modalBox'>
            <div className='modalBoxBody'>
              <div tw='absolute right-0 top-0 hidden pr-4 pt-4 sm:block z-10'>
                {!hideXButton ? (
                  <button
                    type='button'
                    tw='rounded-md bg-gray-800 text-gray-100 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                    onClick={onClose}
                  >
                    <span tw='sr-only'>Close</span>
                    <IoClose tw='h-6 w-6' />
                  </button>
                ) : null}
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </ModalStyled>
  )
}

export default Modal

const ModalStyled = styled.div`
  ${tw`pointer-events-none opacity-0`};
  &.showModal {
    ${tw`pointer-events-auto opacity-100`};
  }
  .modalWrapper {
    ${tw`relative z-50`};
  }
  .modalOverlay {
    ${tw`fixed inset-0 bg-black/70 bg-opacity-75 select-none
    opacity-0 transform transition-opacity ease-in duration-500`};
  }
  .showOverlay {
    ${tw`pointer-events-auto ease-out opacity-100`};
  }
  .modalBoxWrapper {
    ${tw`fixed inset-0 z-10 overflow-y-auto opacity-0 transform transition duration-200 translate-y-4 sm:translate-y-0 sm:scale-95`};
  }
  .showBoxWrapper {
    ${tw`opacity-100 translate-y-0 sm:scale-100`};
  }
  .modalBox {
    ${tw`flex min-h-full items-center justify-center p-4 text-center sm:p-0`};
  }
  .modalBoxBody {
    ${tw`relative transform overflow-hidden rounded-lg border border-white
    bg-black text-white
    px-4 py-4 text-left shadow-xl transition-all w-full
    sm:my-8 sm:w-full sm:max-w-lg sm:p-6`};
  }
`