import tw from 'twin.macro'
import styled from '@emotion/styled'
import clsx from 'clsx'
import {
  FieldErrors,
  FieldValues,
  UseFormRegister
} from 'react-hook-form'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label:        string
  id:           string
  register:     UseFormRegister<FieldValues>
  errors:       FieldErrors
  type?:        string
  required?:    boolean
  disabled?:    boolean
}

const Input: React.FC<InputProps> = ({
  label,
  id,
  type = 'text',
  required = false,
  disabled = false,
  register,
  errors,
  ...props
}) => {
  return (
    <InputStyled>
      <label
        tw='block text-sm font-medium leading-6 text-cyan-100'
        htmlFor={id}
      >{label}
      </label>
      <div tw='mt-2'>
        <input
          {...props}
          id={id}
          type={type}
          autoComplete={props?.autoComplete || id}
          disabled={disabled}
          {...register(id, { required })}
          className={clsx('inputItem',
            errors[id] && 'inputError',
            disabled && 'inputDisabled'
          )}
        />
      </div>
      {errors[id] && (
        <div className='helpTextError'>{label} is required</div>
      )}
    </InputStyled>
  )
}

export default Input

const InputStyled = styled.div`
  .inputItem {
    ${tw`form-input bg-white block w-full rounded-md border-0 py-3.5 text-black
    shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2
    focus:ring-inset focus:ring-[#c9da2b] sm:text-sm sm:leading-6`};
  }
  .inputError {
    ${tw`focus:ring-danger-500`};
  }
  .inputDisabled {
    ${tw`opacity-80 cursor-default`};
  }
  .helpTextError {
    ${tw`text-danger-300 text-xs mt-1.5`};
  }
`