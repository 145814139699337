import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import MacOSCard from './MacOSCard'

import Button from '../components/Button'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { dataObj } from '../constants/superPower'

const UnlockSuperPower: React.FC = () => {
  const {
    quizResult,
    reset: resetQuiz
  } = useQuiz()
  const {
    mainSwiper,
    setCurrentSlideIndex,
    setShowUnlockSuperPower,
    setShowFormInfo,
  } = useCommon()

  const dataObjState: any = React.useMemo(() => {
    switch (quizResult) {
      case 'The Creator':
        return dataObj.theCreator
      case 'The Explorer':
        return dataObj.theExplorer
      case 'The Hero':
        return dataObj.theHero
      case 'The Magician':
        return dataObj.theMagician
      case 'The Caregiver':
        return dataObj.theCaregiver
      case 'The Jester':
        return dataObj.theJester
      default:
        return
    }
  }, [quizResult])

  const handleRedoQuiz = () => {
    resetQuiz()
    setShowUnlockSuperPower(false)
    setShowFormInfo(false)
    setCurrentSlideIndex(0)
    mainSwiper?.slideTo(0)
  }

  return (
    <UnlockSuperPowerStyled>
      <MacOSCard isLight>
        <div>
          <img
            tw='!object-contain !w-full !h-full relative z-[1]'
            src={dataObjState?.banner}
          />
        </div>
        <div tw='mt-10 px-4'>
          <div tw='text-center text-lg font-medium mb-3'>{dataObjState?.category}</div>
          <div tw='text-center uppercase text-3xl md:text-5xl font-semibold mb-1'>{dataObjState?.title}</div>
          <div tw='text-center text-gray-700'>{dataObjState?.desc}</div>
          <div tw='md:px-8 mb-64'>
            <div className='divider' />
            <div tw='grid grid-cols-1 lg:grid-cols-2 gap-6'>
              <div tw='relative mb-10 col-span-1 h-[50vw] md:h-auto'>
                <div className='box1'>&nbsp;</div>
                <div className='box2'>
                  <img
                    tw='!object-cover object-center !w-full !h-full relative z-[1]'
                    src={dataObjState?.thumb}
                  />
                </div>
              </div>
              <div tw='col-span-1'>
                <div className='label'>Style Guide</div>
                <div tw='mt-3 mb-6 flex items-center gap-6'>
                  {dataObjState?.styleGuideColors?.map((i: any) => (
                    <div key={i} className='styleGuide' style={{ backgroundColor: i }}>&nbsp;</div>
                  ))}
                </div>

                <div className='label'>Ruler Brands</div>
                <div tw='mt-3 mb-6 flex items-center gap-6 md:w-[60%]'>
                  {dataObjState?.brands?.map((i: any) => (
                    <div key={i?.key} className='rulerBrand'>
                      <img
                        tw='!object-contain !w-full !h-full relative z-[1]'
                        src={i?.image}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='divider' />
            <div tw='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div>
                <div className='label'>Brand Motto</div>
                <div className='largeText'>{dataObjState?.brandMotto}</div>
              </div>
              <div>
                <div className='label'>Brand Voice</div>
                <div className='largeText' tw='flex flex-col gap-6'>
                  {dataObjState?.brandVoice?.map((i: any) => (
                    <div key={i} className='brandVoiceTag'>{i}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MacOSCard>

      <div className='wrapToaster'>
        <div>
          <svg xmlns='http://www.w3.org/2000/svg' width='58' height='58' fill='none'>
            <path fill='#fff' d='M29 58c16.016 0 29-12.984 29-29S45.016 0 29 0 0 12.984 0 29s12.984 29 29 29Z'/>
            <path fill='#000' fillOpacity='.7' stroke='#fff' strokeWidth='3' d='M47 20.044c0 1.038-.423 2.075-1.184 2.863L27.51 40.827c-.888.871-2.03 1.245-3.213 1.162H24a4.32 4.32 0 0 1-2.918-1.162l-8.878-8.711a4.002 4.002 0 0 1 0-5.725c.803-.788 1.86-1.161 2.917-1.161a4.32 4.32 0 0 1 2.917 1.161l6.258 6.14L39.94 17.182c1.606-1.576 4.227-1.576 5.834 0A4.02 4.02 0 0 1 47 20.045Z'/>
          </svg>
        </div>
        <div tw='text-center'>Kindly check your email for the PDF Inspiration Kit.</div>
        <div className='buttonGroup'>
          <Button href='https://asure.bio/' title='Asure Bio' variant='primary'>Back to Home</Button>
          {/* <Button href='https://asure.bio/' target='_parent' variant='primary'>Back to Home</Button> */}
          <Button variant='primary' onClick={handleRedoQuiz}>Redo Quiz</Button>
        </div>
      </div>
    </UnlockSuperPowerStyled>
  )
}

export default UnlockSuperPower

const UnlockSuperPowerStyled = styled.div`
  .divider {
    ${tw`h-[1px] w-full bg-gray-300/70 my-10`};
  }
  .box1 {
    ${tw`w-[90%] h-[50vw] md:w-[394px] md:h-[262px] bg-[#eeede9] rounded-[24px]`};
  }
  .box2 {
    ${tw`absolute top-[30px] left-[30px] w-[90%] h-[50vw] md:w-[394px] md:h-[262px] overflow-hidden box-border bg-[#fff] rounded-[24px] border-[6px] border-white`};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .styleGuide {
    ${tw`w-[60px] h-[60px] rounded-full`};
  }
  .label {
    ${tw`text-lg text-[#737373]`};
  }
  .largeText {
    ${tw`text-2xl mt-3`};
  }
  .brandVoiceTag {
    ${tw`flex items-center justify-center text-center h-[72px] rounded-[24px] bg-[#eeede9] w-full`};
  }
  .wrapToaster {
    ${tw`flex flex-col items-center gap-4 px-4 py-6 mt-12 mb-20`};
  }
  .buttonGroup {
    ${tw`flex items-center gap-2`};
  }
`