import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import CardHeader from '../components/CardHeader'
import useDebounce from '../hooks/useDebounce'
import useCommon from '../hooks/zustand/useCommon'
import useQuiz from '../hooks/zustand/useQuiz'
import { answerQ7, questionQ7 } from '../constants'

import Img01 from '../assets/q7/q7-01.jpg'
import Img02 from '../assets/q7/q7-02.jpg'
import Img03 from '../assets/q7/q7-03.jpg'
import Img04 from '../assets/q7/q7-04.jpg'
import Img05 from '../assets/q7/q7-05.jpg'
import Img06 from '../assets/q7/q7-06.jpg'

const Quiz07: React.FC = () => {
  const { mainSwiper } = useCommon()
  const { q7, setQ7 } = useQuiz()
  const debouncedAnswer = useDebounce(q7, 300)

  React.useEffect(() => {
    if (debouncedAnswer !== -1) {
      setTimeout(() => {
        mainSwiper?.slideNext()
      }, 50)
    }
  }, [debouncedAnswer])

  const handleSelectAnswer = (idx: number) => {
    if (q7 === idx) {
      // setQ7(-1)
    } else {
      setQ7(idx)
    }
  }

  const makeImg = React.useCallback((idx: number) => {
    switch (idx) {
      case 0:
        return Img01
      case 1:
        return Img02
      case 2:
        return Img03
      case 3:
        return Img04
      case 4:
        return Img05
      case 5:
        return Img06
      default:
        return
    }
  }, [])

  return (
    <QuizStyled>
      <CardHeader num='07' title={questionQ7} />

      <div className='wrapImage'>
        <div className='contentImage'>
          <div className='gridImage'>
            {answerQ7.map((i: string, idx: number) => (
              <div
                key={i}
                className={clsx(
                  `quiz07Img`,
                  {
                    p07Normal: idx !== q7 && q7 === -1,
                    p07Disable: idx !== q7 && q7 !== -1,
                    p07Active: idx === q7,
                  })
                }
                onClick={() => { handleSelectAnswer(idx) }}
              >
                <img
                  tw='!object-contain !w-full !h-full relative z-[1]'
                  src={makeImg(idx)}
                />
                <div className='p07Text'>{i}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </QuizStyled>
  )
}

export default Quiz07

const QuizStyled = styled.div`
  ${tw`block`};

  .wrapImage {
    ${tw`flex items-center justify-center w-full mb-[100px]`};
  }
  .contentImage {
    ${tw`flex items-center justify-center w-[80%] md:w-[65%] lg:w-[45%] xl:w-[35%]`};
  }
  .gridImage {
    ${tw`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4`};
  }
  .quiz07Img {
    ${tw`col-span-1 relative select-none`};
    > img {
      ${tw`border border-solid border-white/0 rounded-[24px]`};
    }
  }
  .p07Normal {
    ${tw`cursor-pointer`};
  }
  .p07Disable {
    ${tw`cursor-pointer grayscale`};
  }
  .p07Active {
    ${tw`cursor-pointer`};
    > img {
      ${tw`border border-solid border-[#C9DA2B]`};
    }
  }
  .p07Text {
    ${tw`absolute w-full leading-[18px] md:leading-[23px] px-2 text-left bottom-[10px] z-[2] font-medium
    text-sm md:text-base lg:text-base`};
  }
`