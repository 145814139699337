import BannerTheCaregiver from '../assets/sp/the-caregiver/the-caregiver-banner.png'
import BannerTheCreator from '../assets/sp/the-creator/the-creator-banner.png'
import BannerTheHero from '../assets/sp/the-hero/the-hero-banner.png'
import BannerTheJester from '../assets/sp/the-jester/the-jester-banner.png'
import BannerTheExplorer from '../assets/sp/the-explorer/the-explorer-banner.png'
import BannerTheMagician from '../assets/sp/the-magician/the-magician-banner.png'

import ThumbTheCaregiver from '../assets/sp/the-caregiver/the-caregiver-thumb.jpg'
import ThumbTheCreator from '../assets/sp/the-creator/the-creator-thumb.jpg'
import ThumbTheHero from '../assets/sp/the-hero/the-hero-thumb.jpg'
import ThumbTheJester from '../assets/sp/the-jester/the-jester-thumb.jpg'
import ThumbTheExplorer from '../assets/sp/the-explorer/the-explorer-thumb.jpg'
import ThumbTheMagician from '../assets/sp/the-magician/the-magician-thumb.jpg'

import BrandTheCaregiver01 from '../assets/sp/the-caregiver/the-caregiver-brand-1.png'
import BrandTheCaregiver02 from '../assets/sp/the-caregiver/the-caregiver-brand-2.png'
import BrandTheCaregiver03 from '../assets/sp/the-caregiver/the-caregiver-brand-3.png'
import BrandTheCreator01 from '../assets/sp/the-creator/the-creator-brand-1.png'
import BrandTheCreator02 from '../assets/sp/the-creator/the-creator-brand-2.png'
import BrandTheCreator03 from '../assets/sp/the-creator/the-creator-brand-3.png'
import BrandTheHero01 from '../assets/sp/the-hero/the-hero-brand-1.png'
import BrandTheHero02 from '../assets/sp/the-hero/the-hero-brand-2.png'
import BrandTheHero03 from '../assets/sp/the-hero/the-hero-brand-3.png'
import BrandTheJester01 from '../assets/sp/the-jester/the-jester-brand-1.png'
import BrandTheJester02 from '../assets/sp/the-jester/the-jester-brand-2.png'
import BrandTheJester03 from '../assets/sp/the-jester/the-jester-brand-3.png'
import BrandTheExplorer01 from '../assets/sp/the-explorer/the-explorer-brand-1.png'
import BrandTheExplorer02 from '../assets/sp/the-explorer/the-explorer-brand-2.png'
import BrandTheExplorer03 from '../assets/sp/the-explorer/the-explorer-brand-3.png'
import BrandTheMagician01 from '../assets/sp/the-magician/the-magician-brand-1.png'
import BrandTheMagician02 from '../assets/sp/the-magician/the-magician-brand-2.png'
import BrandTheMagician03 from '../assets/sp/the-magician/the-magician-brand-3.png'


export const dataObj = {
  theCaregiver: {
    category: 'Service',
    title: 'The Caregiver',
    desc: 'To Nurture and Care for Others',
    styleGuideColors: ['#AE5847', '#D5AA90', '#869193'],
    brandMotto: 'Guiding Hearts, Nurturing Lives.',
    brandVoice: ['Compassionate', 'Understanding', 'Reassuring'],
    banner: BannerTheCaregiver,
    thumb: ThumbTheCaregiver,
    brands: [
      {key: 1, image: BrandTheCaregiver01},
      {key: 2, image: BrandTheCaregiver02},
      {key: 3, image: BrandTheCaregiver03},
    ]
  },
  theCreator: {
    category: 'Innovation',
    title: 'The Creator',
    desc: 'Challenge Conventions',
    styleGuideColors: ['#E593BF', '#D6E04A', '#EF4123'],
    brandMotto: 'Embrace the Extraordinary, Defy the Impossible',
    brandVoice: ['Ambitious', 'Inspirational', 'Confident'],
    banner: BannerTheCreator,
    thumb: ThumbTheCreator,
    brands: [
      {key: 1, image: BrandTheCreator01},
      {key: 2, image: BrandTheCreator02},
      {key: 3, image: BrandTheCreator03},
    ]
  },
  theHero: {
    category: 'Mastery',
    title: 'The Hero',
    desc: 'To Create A Better World',
    styleGuideColors: ['#61BFC6', '#EE334C', '#002D3F'],
    brandMotto: "Where there's a Will, there's a Way",
    brandVoice: ['Bold', 'Confident', 'Inspiring'],
    banner: BannerTheHero,
    thumb: ThumbTheHero,
    brands: [
      {key: 1, image: BrandTheHero01},
      {key: 2, image: BrandTheHero02},
      {key: 3, image: BrandTheHero03},
    ]
  },
  theJester: {
    category: 'Entertainment',
    title: 'The Jester',
    desc: 'To Bring Joy and Laughter',
    styleGuideColors: ['#DE1F26', '#F7D117', '#0B4E9D'],
    brandMotto: 'Life is a stage, enjoy the show.',
    brandVoice: ['Playful', 'Witty', 'Charismatic'],
    banner: BannerTheJester,
    thumb: ThumbTheJester,
    brands: [
      {key: 1, image: BrandTheJester01},
      {key: 2, image: BrandTheJester02},
      {key: 3, image: BrandTheJester03},
    ]
  },
  theExplorer: {
    category: 'Freedom',
    title: 'The Explorer',
    desc: 'Explore The Unknown',
    styleGuideColors: ['#A6A5A5', '#2CB563', '#0C2A47'],
    brandMotto: 'You Only Get One Life, Make it Count',
    brandVoice: ['Curious', 'Daring', 'Optimistic'],
    banner: BannerTheExplorer,
    thumb: ThumbTheExplorer,
    brands: [
      {key: 1, image: BrandTheExplorer01},
      {key: 2, image: BrandTheExplorer02},
      {key: 3, image: BrandTheExplorer03},
    ]
  },
  theMagician: {
    category: 'Power',
    title: 'The Magician',
    desc: 'Make Dreams Come Through',
    styleGuideColors: ['#0B4E9D', '#EDBE53', '#3F2C79'],
    brandMotto: 'Magic Flows Where Belief takes Hold',
    brandVoice: ['Enigmatic', 'Authoritative', 'Inspiring'],
    banner: BannerTheMagician,
    thumb: ThumbTheMagician,
    brands: [
      {key: 1, image: BrandTheMagician01},
      {key: 2, image: BrandTheMagician02},
      {key: 3, image: BrandTheMagician03},
    ]
  },
}
