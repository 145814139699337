import React from 'react'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import ReactGA from 'react-ga4'
import MainSwiper from './views/MainSwiper'
import useToast from './hooks/useToast'
import useCommon from './hooks/zustand/useCommon'
import UnlockSuperPower from './views/UnlockSuperPower'
import TopNavigationBar from './components/TopNavigationBar'

import BG from '../assets/bg/bg-02.png'

const styles = {
  // Move long class sets out of jsx to keep it scannable
  container: ({ hasBackground }: { hasBackground: boolean }) => [
    tw`flex flex-col items-center justify-center min-h-screen text-white bg-black`,
    hasBackground && tw`bg-[url('https://i.imgur.com/rtqmNPt.png')] bg-contain bg-center bg-no-repeat`,
  ],
}

ReactGA.initialize('G-2QDYX4GMFG')

const App = () => {
  const { toasterComponent } = useToast()
  const {
    showUnlockSuperPower
  } = useCommon()

  return (
    <React.Fragment>
      <MainStyled>
        <div css={styles.container({ hasBackground: !showUnlockSuperPower })}>
          <TopNavigationBar />
          <div tw='container mx-auto px-3'>
            <h1 tw='text-[#F30080] font-bold'>Brand Personality Quiz</h1>
            <div tw='mt-6 mb-6 md:mb-12 text-[40px] leading-[50px] md:text-[60px] md:leading-[70px] font-bold'>UNLOCK YOUR<br />SUPERPOWER</div>
          </div>
          <QuizSwiperStyled
            className={clsx({
              hideQuizSwiper: showUnlockSuperPower,
              showQuizSwiper: !showUnlockSuperPower,
            })}
          >
            <div className='mainWrapper'>
              <MainSwiper />
            </div>
          </QuizSwiperStyled>
          <SuperPowerStyled
            className={clsx({
              hideUnlock: !showUnlockSuperPower,
              showUnlock: showUnlockSuperPower,
            })}
          >
            <div className='unLockWrapper'>
              <UnlockSuperPower />
            </div>
          </SuperPowerStyled>
        </div>
        {toasterComponent}
      </MainStyled>
    </React.Fragment>
  )
}
export default App

const SuperPowerStyled = styled.div`
  ${tw`hidden w-full`};
  &.hideUnlock {
    ${tw`pointer-events-none !h-[0] overflow-hidden`};
  }
  &.showUnlock {
    ${tw`!block pointer-events-auto`};
  }
`
const QuizSwiperStyled = styled.div`
  ${tw`hidden w-full mb-6 md:mb-0`};
  &.hideQuizSwiper {
    ${tw`pointer-events-none !h-[0] overflow-hidden`};
  }
  &.showQuizSwiper {
    ${tw`!block pointer-events-auto`};
  }
`
const MainStyled = styled.main`
  ${tw`min-h-screen`};
  .mainWrapper {
    ${tw`container mx-auto px-3 py-3 flex flex-col items-center w-full min-h-screen`};
    h1 {
      ${tw`w-full mt-6 mb-3 text-xl md:text-2xl`};
    }
    h2 {
      ${tw`w-full mt-6 mb-3 text-center text-xl md:text-2xl text-[#C9DA2B]`};
    }
  }
  .unLockWrapper {
    ${tw`container mx-auto px-3 py-3 w-full flex flex-col min-h-screen`};
    h1 {
      ${tw`w-full mt-6 mb-3 text-xl md:text-2xl`};
    }
  }
`