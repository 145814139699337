const postContactForm = async (data: any = {}) => {
  try {
    const response = await fetch('https://dev-api.insurio.0x442.com/rest/asure/quiz-mail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  const jsonData = await response.json()
  return {
    status: response.status,
    data: jsonData
  }
  } catch (err: any) {
    console.error(`Post error: ${err.message}`)
    return null
  }
}

export default postContactForm